<template>
    <div class="test-timer">
        <div class="test-timer__title">
            <div class="test-timer__pic">
                <img class="test-timer__img" src="~assets/img/favicons/sand-clock.svg" alt="" width="20"
                     height="28">
            </div>
            Тест будет доступен через:
        </div>

        <vue-easy-pie-chart
            class="js-pie-chart-vue testing__timer mb-50"
            :size="194"
            :scale-length="0"
            :line-width="11"
            :percent="percent"
            bar-color="#fcc142"
            track-color="#e9e9e9"
        >
            <span class="testing__timer-body">
                <span class="testing__time">{{computedTime.hours}}:{{computedTime.minutes}}:{{computedTime.seconds}}</span>
            </span>
        </vue-easy-pie-chart>

<!--        <router-link tag="a" to="/" class="test__link-to-main">-->
<!--            Вернуться на главную-->
<!--        </router-link>-->
    </div>
</template>

<script>
    import VueEasyPieChart from 'vue-easy-pie-chart';
    import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css';
    import functions from 'assets/js/functions';
    export default {
        name: 'Timer',
        props: ['info', 'onTimerStop'],
        components: { VueEasyPieChart },
        data() {
            return {
                functions,
                time: this.info.time,
                timer: null
            };
        },
        computed: {
            computedTime() {
                return this.functions.msToTime(this.time * 1000);
            },
            percent() {
                return 100 * (this.time / (60 * 60));
            }
        },
        created() {
            this.timer = setInterval(() => {
                if (this.time === 0) {
                    clearInterval(this.timer);
                    this.onTimerStop();
                }
                this.time--;
            }, 1000);
        }
    };
</script>

<style scoped>

</style>
